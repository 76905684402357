//
//
//
//
//
//
//
//
//
//
//
//
//

	import { inviteService } from "@/service/inviteService";
	export default {
		computed: {
			meetingStatus() {
				var meetingStatus,
					status = this.meetingInfo.status;
				switch(true) {
					case status == -1:
						meetingStatus = {
							type: "default",
							disabled: true,
							txt: "会议已取消"
						}
						break;
					case status < 2:
						meetingStatus = {
							type: "default",
							disabled: true,
							txt: "会议尚未开始"
						}
						break;
					case status == 2:
						meetingStatus = {
							type: "primary",
							disabled: false,
							txt: "加入会议"
						}
						break;
					default:
						meetingStatus = {
							type: "default",
							disabled: true,
							txt: "会议已结束"
						}
				}
				return meetingStatus;
			},
			endTime() {
				let dateStr = this.$formatDate(this.meetingInfo.startTime, "yyyy/MM/dd HH:mm:ss");
				return this.$formatDate(
					new Date(dateStr).getTime() +
					this.meetingInfo.duration * 60000,
					"yyyy-MM-dd HH:mm:ss"
				)
			}
		},
		data() {
			return {
				meetingInfo: {
					name: "罗氏上海测试会议",
					userName: "张三丰",
					startTime: "2020-05-20T00:00:00",
					duration: 0,
					init: true,
					status: 3 //-1已取消 0未预约 1预定成功 2进行中 3已结束 4取消有损失
				}
			}
		},
		mounted() {
			if(this.$route.query.confId) {
				this.iStorage.remove("iconfId")
				this.iStorage.set("meetingId", this.$route.query.confId.split("|")[0]);
				this.iStorage.set("confId", this.$route.query.confId.split("|")[1]);
			}
			if(this.$route.query.iconfId) {
				this.iStorage.remove("confId")
				this.iStorage.set("meetingId", this.$route.query.iconfId.split("|")[0]);
				this.iStorage.set("iconfId", this.$route.query.iconfId.split("|")[1]);
			}
			this.getMeetingInfo();
		},
		methods: {
			joinMeeting() {
				this.$router.push({
					path: "/joinMeeting",
					query: this.$route.query
				});
			},
			getMeetingInfo() {
				let params = {
					MettingId: this.iStorage.get('meetingId')
				};
				inviteService.QueryMeetingInfo(params).then(res => {
					if(res.success) {
						this.meetingInfo = res.content;
						this.meetingInfo.startTime = this.meetingInfo.startTime.replace(/-/g, "/")
						if(res.content.status == 0 || res.content.status == 1) {
							setTimeout(() => this.getMeetingInfo(), 60000);
						}
					}
				});
			}
		}
	}
