import { request } from "./request";

// var tenantCode = iStorage.get("tenant");

export const inviteService = {
	/**
	 * 获取参会的会议信息
	 */
	QueryMeetingInfo: (params) => {
		return request.get("/api/resource/video/Metting/Query", { params });
	},
	/**
	 * 加入会议
	 */
	JoinMeeting: (params) => {
		return request.post("/api/resource/video/Attend/Join", params);
	},
	// 参会界面获取参会人信息
	QueryInvitation: (params) => {
		return request.post("/api/resource/video/Invitation/QueryInvitation", params);
	},
	// 查询免责声明内容
	QueryDisclaimer: (params) => {
		return request.get("/api/resource/video/ReMark/QueryDisclaimer", { params });
	},
	// 发送验证码
	SendCaptcha: (params) => {
		return request.post("/api/resource/video/Verification/SendCaptcha", params);
	},
	// 验证验证码
	CheckCaptcha: (params) => {
		return request.post("/api/resource/video/Verification/CheckCaptcha", params);
	},
	// 同意免责申明
	AgreeDisclaimer: (params) => {
		return request.post("/api/resource/video/Invitation/AgreeDisclaimer", params);
	},
	// 获取url
	QueryUrl: (params) => {
		return request.post("/api/resource/video/Invitation/QueryUrl", params);
	},
}